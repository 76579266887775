.attributes {
  &__wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 10px;
    &__color {
      box-sizing: content-box;
      height: 25px !important;
      border: 1px solid rgb(204 204 204 / 100%);
      width: 25px !important;
      cursor: pointer;
      border: 2px solid transparent;
      transition: border 0.2s ease-in-out;

      &.active {
        border: 2px solid #0000008f;
      }
    }
    &__size {
      align-items: center;
      justify-content: center;
      display: flex;
      height: 25px;
      font-size: 12px;
      text-transform: uppercase;
      min-width: 25px;
      border: 1px solid #000;
      transition: border 0.2s ease-in-out;
      &.active {
        border: 2px solid #0000008f;
        // position: relative;
        // // border: 1px solid #000;
        // &::after {
        //   position: absolute;
        //   content: "";
        //   width: 8px;
        //   height: 0.9px;
        //   background-color: #000;
        //   bottom: -10px;
        //   left: 0;
        //   right: 0;
        //   margin: auto;
        // }
      }
    }
  }
}
