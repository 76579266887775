.productDetails {
  width: 100%;
  padding-top: 20px;
  border-top: 1px solid #000;
  transition: top 350ms ease;
  background-color: rgb(255 255 255 / 95%);
  height: 100%;
  position: relative;

  @media screen and (min-width: 768px) {
    // inset: unset;
    // padding-top: 20px;
    // top: 62px;
    // right: 0;
    // overflow-y: scroll;
    // max-width: 50%;
  }

  overflow-y: scroll;


  &__parent {}

  &__wrapper {
    position: relative;
    z-index: 9;
    display: flex;

    flex-direction: column;
    gap: 20px;
    padding-left: 5px;


    &__name {
      text-transform: uppercase;
      font-size: 11px;
      padding-left: 10px;
    }

    &__price {
      padding-left: 10px;
      font-size: 11px;

      &.sale {
        text-decoration: line-through;
      }

      &__sale {
        background-color: #ffe800;
        color: #0000008a;
        padding: 0 5px;
        margin-left: 10px;

        display: inline-block;
        font-size: 12px;
        width: fit-content;
        display: flex;
      }
    }

    &__description {
      padding-left: 10px;
      max-width: 95%;

      &__text {
        font-size: 12px;
        color: #444;
      }
    }

    &__related {
      z-index: 9999999;

      &__title {
        font-size: 13px;
        padding-top: 30px;
        padding-left: 10px;
      }

      &__wrapper {
        z-index: -1;
        width: 100%;
        padding-top: 0px;
        display: flex;
        flex-wrap: wrap;
        gap: 3px;
        margin-bottom: 50px;

        &__item {
          height: 100%;
          margin-bottom: 20px;
          width: 24%;

          @media screen and (max-width: 768px) {
            width: 49%;
          }
        }
      }
    }

    &__btn {
      padding-left: 10px;

      &--btn {
        font-size: 11px;
      }
    }
  }
}