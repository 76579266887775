.accordion {
  &__title {
    padding: 5px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #000;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 13px;
    cursor: pointer;
    user-select: none;
    &__icon {
      width: 100%;
      position: relative;
      &__first {
        color: #000;
        right: 10px;
        position: absolute;
        top: -5px;
        transition: all 400ms ease;
        font-size: 9px;
        transform: rotate(90deg);
        font-weight: 500;
      }
      &__second {
        transform: rotate(0deg);
        transition: all 400ms ease;
        right: 10.5px;
        top: -5.7px;
        position: absolute;
        color: #333;
        font-size: 9px;
        font-weight: 500;
      }
      &.active {
        .accordion__title__icon__first {
          transform: rotate(90deg);
          top: -5px;
        }
        .accordion__title__icon__second {
          transform: rotate(90deg);
          top: -5px;
          right: 10px;
        }
      }
    }
  }
  &__wrapper {
    display: grid;
    grid-template-rows: 0fr;
    overflow: hidden;
    transition: grid-template-rows 200ms ease-in;
    &.active {
      grid-template-rows: 1fr;
    }
    &__collapsible {
      min-height: 0;
    }
  }
}
