.button {
  all: unset;
  // box-sizing: border-box;
  border: 1px solid rgb(204 204 204 / 100%);
  border-radius: 0;
  cursor: pointer;
  text-align: center;
  min-width: 128px;
  width: auto;
  height: 32px;
  display: inline-block;
  font-size: 14px;
  letter-spacing: 0.7px;
  text-decoration: none;
  padding: 2px;
  text-transform: uppercase;
  //to fix to remove width and use padding instead
  &--xs {
    min-width: 50px;
  }
  &--sm {
    min-width: 130px;
    width: auto;
    font-weight: 300;
    height: 24px;
    font-size: 11px;
    border: 1px solid #000;
  }
  &--md {
    min-width: 90px;
    width: auto;
    height: 32px;
  }
  &--lg {
    min-width: 128px;
    width: auto;
    height: 32px;
  }
  &--outOfStock {
    cursor: not-allowed;
  }
  &--disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}
