.cartCard {
  &__wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    max-height: 300px;
    gap: 13px;
    margin-bottom: 30px;
    &__left {
      flex: 1;
      width: 100%;
      &__img {
        max-width: 100%;
        height: 100%;
      }
    }
    &__right {
      flex: 1;
      display: flex;
      flex-direction: column;
      &__top {
        flex: 1;
        &__name {
          font-size: 12px;
          font-weight: 400;
          text-transform: uppercase;
          letter-spacing: 0.4px;
        }
        &__price {
          padding: 10px 0px;
          letter-spacing: 0.4px;
          font-weight: 400;
          font-size: 12px;
          text-transform: uppercase;
        }
        &__attr {
          padding-top: 10px;
          font-size: 10px;
          text-transform: uppercase;
        }
      }
      &__delete {
        margin-top: 10px;
        width: fit-content;
        cursor: pointer;

        font-size: 9px;
      }
    }
  }
}
