.home {
  &__wrapper {
    height: 100vh;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    &__card {
      height: 100%;
      width: 100vw;
      @media screen and (max-width: 768px) {
        -webkit-scroll-snap-align: start;
        scroll-snap-align: start;
      }
      position: sticky;
      top: 0;
      scroll-snap-stop: always;
      &__img {
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}
.drawJSX {
  height: 100%;
  flex: 1;
  width: 90%;
  @media screen and (min-width: 768px) {
    width: 50%;
  }
  display: flex;
  flex-direction: column;
  padding: 10px;
  z-index: 999;
  margin: auto;
  position: relative;
  // justify-content: center;
  &__body {
    position: absolute;
    top: 40%;
    left: 0px;
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 20px;
    h1 {
      color: #eeeeeecc;
      text-align: center;
      font-weight: 400;
    }
    p {
      color: #eeeeeece;
      // color: #eeeeeeb0;
      // text-align: center;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
    }
    &__btn {
      width: 100%;
      background-color: #ffffffce !important;
      &--black {
        background-color: #000 !important;
        color: white !important;
        border: none !important;
      }
      // background-color: rgba(255, 255, 255, 0.781) !important;
    }
  }
}
