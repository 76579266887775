.product {
  position: relative;

  &__wrapper {
    position: relative;

    @media screen and (min-width: 768px) {
      display: flex;
      // flex-direction: column;
      // max-width: 50%;
    }

    &--isOpen {
      // height: 100vh;
      overflow: hidden;
    }

    &__img {
      max-width: 100%;
    }

    &__skeleton {
      &__item {
        .skeletonCard__wrapper__media {
          height: 500px;
        }

        .skeletonCard__wrapper__lower__name,
        .skeletonCard__wrapper__lower__price {
          display: none;
        }
      }
    }
  }
}

#ReactSimpleImageViewer {
  z-index: 9999999999999999;
  background-color: rgba(255, 255, 255, 0.953);

  .react-simple-image-viewer__close {
    opacity: 1;
    font-weight: 100;
    color: #333;
    font-size: 60px;
  }
}