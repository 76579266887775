.hero-section-skeleton {
    position: relative;
    width: 100%;
    height: 100%;
    /* Adjust height as needed */
    border-radius: 8px;
    background-color: #f3f3f3;
}

.hero-section-skeleton__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right,
            #f3f3f3 0%,
            #e9e9e9 50%,
            #f3f3f3 100%);
    background-size: 200% auto;
    animation: loading 1.5s infinite;
}

.hero-section-skeleton__content {
    position: absolute;
    top: 0;
    left: 0px;
    /* width: calc(100% - 40px); */
    /* height: 60px; */
    height: 100%;
    width: 100%;
    background: linear-gradient(to right,
            #f3f3f3 0%,
            #00000018 50%,
            #f3f3f3 100%);
    background-size: 200% auto;
    animation: loading .8s infinite;
}

@keyframes loading {
    0% {
        background-position: 100% 50%;
    }

    100% {
        background-position: -100% 50%;
    }
}