.textField {
  all: unset;
  max-width: 100%;
  text-transform: uppercase;
  border-bottom: 1px solid #000;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  font-weight: 400;
}
