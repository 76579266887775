.terms-container {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    margin: 20px;

    h2 {
        color: #333;
        // padding-bottom: px;
    }

    ul {

        list-style-type: none;
        padding: 0;
        padding-top: 18px;
    }

    li {
        margin-bottom: 10px;
    }

    hr {
        margin: 20px 0;
        border: 0;
        border-top: 1px solid #ccc;
    }
}

.terms-section {
    margin-bottom: 20px;
}



/* Add more styles as needed */