.customSkeleton {
  background-color: #e1e1e1; /* Light gray */
  animation: customLoaderAnimation 1.5s ease-in-out infinite;
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
}

@keyframes customLoaderAnimation {
  0% {
    background-color: #e1e1e1; /* Light gray */
  }
  50% {
    background-color: #f1f1f1; /* Slightly lighter gray */
  }
  100% {
    background-color: #d1d1d1; /* Dark gray */
  }
}
