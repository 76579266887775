.payment {
  &__wrapper {
    width: 100%;
    background: white;
    position: relative;
    padding: 70px 20px 40px 20px !important;
    &__icon {
      position: absolute;
      left: 20px;
      top: 20px;
    }
    &__radio {
      // margin-top: 10px;
    }
    &__title {
      margin: 30px 0px 10px 0px;
      font-size: 13px;
      letter-spacing: 0.4px;
      font-weight: 400;
    }
  }
}
