.priceRange {
  padding: 20px 5px;
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__item {
      font-weight: 300;
      color: #000;
      font-size: 10px;
      letter-spacing: 0.2px;
    }
  }
  &__slider {
    * {
      transition: all 0.1s ease-in-out;
    }
    width: 100%;
    margin: 20px 0px;
    height: 1px;
    background: #000;

    .thumb {
      width: 6px;
      height: 6px;
      cursor: pointer;
      background: #000;
      border-radius: 50%;
      border: 1px solid #000;
      top: -3px;
    }
    .track-0,
    .track-2 {
      background-color: #eee !important;
      height: 1px !important;
    }
    .active {
    }
  }
}
