.productsGrid {
  width: 100%;
  &__wrapper {
    z-index: -1;
    width: 100%;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
    margin-bottom: 50px;
    &__item {
      height: 100%;
      margin-bottom: 20px;
      width: 49%;
      @media screen and (max-width: 768px) {
        width: 49%;
      }
    }
  }
}
