.account {
  &__wrapper {
    padding: 0px 20px;
    &__heading {
      font-size: 25px;
      text-transform: uppercase;
      text-align: center;
      font-weight: 400;
    }
    &__history {
      padding: 40px 0px;
      &__table {
        padding-top: 10px;
      }
      &__heading {
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.3px;
      }
    }
    &__details {
      &__info {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        &__item {
          font-size: 12px;
          font-weight: 300;
        }
      }
    }
    &__logout {
      margin-top: 50px;
      font-size: 13px;
    }
  }
}
