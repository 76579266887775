.skeletonCard {
  &__wrapper {
    &__media {
      position: relative;
      width: 100%;
      height: 400px;
      @media screen and (max-width: 768px) {
        height: 260px;
      }
      &__image {
        position: absolute;
        object-fit: cover;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: red;
        animation: loader 1.5s ease-in-out infinite;
      }
    }
    &__lower {
      padding-top: 5px;
      //   padding-left: 6px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      &__price {
        padding: 5px;
        width: 100%;
        animation: loader 1.5s ease-in-out infinite;
        &.sale {
          text-decoration: line-through;
        }
      }
      &__name {
        // font-size: 10px;
        padding: 5px;
        width: 100%;
        animation: loader 1.5s ease-in-out infinite;
      }
      &__sale {
        background-color: #ffe800;
        color: #000000;
        padding: 0 2px;
        display: inline-block;
      }
    }
  }
}

@keyframes loader {
  0% {
    background-color: #e1e1e1; /* Light gray */
  }
  50% {
    background-color: #f1f1f1; /* Slightly lighter gray */
  }
  100% {
    background-color: #d1d1d193; /* Dark gray */
  }
}
