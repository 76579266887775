.orderPlaced {
  height: 100vh;
  width: 100%;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  &__wrapper {
    transition: none;
    touch-action: none;
    padding: 40px 50px;
    box-sizing: content-box;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    box-shadow: 0px 10px 15px 10px rgba(0, 0, 0, 0.1);
    gap: 20px;
    z-index: 999999;
    height: 140px;
    width: 200px;
    @media screen and (min-width: 758px) {
      width: 250px;
    }
    &__icon {
      padding: 10px;
      border-radius: 50%;
      border: 1px solid #000;
      display: grid;
      place-items: center;
      &--icon {
        font-size: 40px;
        color: #00cc00;
        animation: fillAnimation 0.6s ease-in;
        animation-duration: 0.5s;
        animation-timing-function: ease-in-out;
      }
    }
    &__heading {
      font-size: 20px;
      letter-spacing: 0.4px;
      font-weight: 500;
    }
    &__description {
      font-size: 10px;
      font-weight: 300;
    }
  }
}

@keyframes fillAnimation {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
