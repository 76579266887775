.cart {
  position: fixed;
  left: -100%;
  top: 0;
  z-index: 88;
  min-height: 100vh;
  min-height: 100dvh;
  z-index: 9999999;
  width: 100%;
  height: 100%;
  overflow: scroll;
  @media screen and (min-width: 768px) {
    width: 40%;
  }
  background-color: #fff;
  transition: left 0.3s ease;
  &--show {
    left: 0px;
  }
  &__wrapper {
    height: 100%;
    position: relative;
    &.empty {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__icon {
      position: absolute;
      left: 20px;
      top: 20px;
    }
    &__empty {
      text-align: center;
      &__label {
        font-size: 13px;
      }
    }
    padding: 70px 20px 20px 20px;
    &__body {
      height: 100%;
      &__title {
        padding-bottom: 20px;
        font-size: 11px;
        font-weight: 600;
        letter-spacing: 0.3px;
      }
      &__bottom {
        background: #fff;
        padding: 20px;
        border-top: 1px solid #aaa;
        bottom: 0px;
        left: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: background-color 0.1s ease;
        // position: absolute;
        &--open {
          left: 0;
          position: fixed;
        }
        &__btn {
          background-color: #000 !important;
          color: white !important;
          border: 1px solid inherit;
          &:hover {
            background-color: white !important;
            color: #000 !important;
            border: 1px solid #000 !important;
          }
        }
        &__right {
          display: flex;
          flex-direction: column;
          position: relative;
          align-items: flex-end;
          &::before {
            position: absolute;
            content: "TOTAL:";
            left: -50px;
            top: -5px;
            padding: 5px;
            letter-spacing: 0.3px;
            font-weight: 500;
            font-size: 12px;
          }
          gap: 5px;
          &__price {
            font-size: 13px;
            letter-spacing: 1px;
            font-weight: 500;
            system: numeric;
            suffix: ",";
            symbols: " ";
          }
          &__vat {
            font-size: 9px;
            text-transform: uppercase;
          }
        }
      }
      &__products {
        padding-bottom: 70px !important;
      }
    }
  }
}
