.login {
  &__wrapper {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    &__heading {
      font-size: 25px;
      text-transform: uppercase;
      text-align: center;
      font-weight: 400;
    }
    &__input {
      font-size: 13px;
      &::placeholder {
        font-size: 13px;
      }
    }
    gap: 20px;
    &__signUp {
      font-size: 10px;
      text-decoration: underline;
      text-align: center;
    }
    &__error {
      font-size: 11px;
      color: crimson;
    }
    &__success {
      font-size: 11px;
      color: mediumseagreen;
    }
  }
}
