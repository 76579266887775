$borderColor: #e7e6e0;

.orderOverview {
  border-radius: 8px;
  border: 1px solid $borderColor;
  &.order-2 {
    @media screen and (max-width: 765px) {
      order: 2;
    }
  }
  &__wrapper {
    padding: 20px;
    &__title {
      border-bottom: 1px solid $borderColor;
      padding-bottom: 8px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    &__subtotalSect {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &__product {
        margin-bottom: 10px;
        font-size: 12px;
      }
    }
    &__products {
      border-bottom: 1px solid $borderColor;
      padding-bottom: 10px;

      &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
        &__name {
          margin: 0px;
          font-size: 12px;
          text-transform: uppercase;
        }
        &__price {
          margin: 0;
          font-size: 12px;
          font-weight: normal;
          color: #5c5c5c;
        }
      }
    }
    &__footer {
      display: flex;
      flex-direction: column;
      gap: 10px;
      &__subtotal {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &__shipping {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &__total {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__price {
          font-weight: 500;
          font-size: 13px;
        }
        &__label {
          font-size: 12px;
          font-weight: 500;
        }
      }
      &__subtotal {
        padding-top: 20px;
        &__label {
          margin: 0;
          font-weight: 500;
          font-size: 12px;
        }
        &__price {
          margin: 0;
          font-size: 12px;
          font-weight: normal;
          color: #5c5c5c;
        }
      }
      &__shipping {
        // padding-top: 15px;
        &__label {
          margin: 0;
          font-size: 12px;
          font-weight: 500;
        }
        &__price {
          margin: 0;
          font-size: 12px;
          color: #5c5c5c;
        }
      }
    }
  }
}
