.spinner {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 3px solid;
  border-color: #dbdcef;
  border-right-color: #000;
  animation: spinner-d3wgkg 1s infinite linear;
}

@keyframes spinner-d3wgkg {
  to {
    transform: rotate(1turn);
  }
}