.AddressForm {
  &__wrapper {
    width: 100%;
    background: white;
    position: relative;
    padding: 70px 20px 40px 20px;
    @media screen and (min-width: 768px) {
      max-width: 600px;
      margin: auto;
    }
    &__icon {
      position: absolute;
      left: 20px;
      top: 20px;
    }
    &__heading {
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 8px;
      text-transform: uppercase;
    }
    &__subheading {
      font-size: 10px;
      font-weight: 300;
      margin-bottom: 20px;
    }
    &__form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      &__input {
        margin-bottom: 10px;
        width: 100%;
        padding-left: 4px;
        font-size: 12px;
        &::placeholder {
          font-size: 10px !important;
        }
      }
      &__btn {
        width: 100%;
        margin-top: 20px;
        background-color: #000;
        border: 1px solid #000;
        color: white;
        width: 4;
        transform: background-color 0.2s ease;
        font-size: 11px;
        &:hover {
          background-color: #fff;
          border: 1px solid #000;
          color: #000;
        }
      }
    }
  }
}
