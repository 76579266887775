.filter {
  &__wrapper {
    padding: 76px 20px 30px 20px;
    position: fixed;
    min-height: 100vh;
    min-height: 100dvh;
    width: 100%;
    top: 0;
    left: -100%;
    z-index: 8888899 !important;
    background: #fff;
    display: flex;
    flex-direction: column;
    transition: left 0.4s ease;
    gap: 20px;
    @media screen and (min-width: 768px) {
      width: 40%;
    }
    &--show {
      left: 0;
    }
    &__icon {
      position: absolute;
      font-size: 13px;
      top: 30px;
      right: 30px;
      font-weight: 200;
    }
    &__bottom {
      flex: 1;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      &__clear {
        font-size: 12px;
        font-weight: 200;
        padding-bottom: 10px;
        cursor: pointer;
        letter-spacing: 0.4px;
        user-select: none;
        &::selection {
          display: none;
        }
      }
    }
  }
}
