.sideBar {
  height: 100vh;
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 99999;
  background-color: white;
  width: 100%;

  @media screen and (min-width: 768px) {
    width: 40%;
  }

  transition: all 0.3s ease-in-out;

  &--show {
    left: 0;
  }

  height: 100%;

  &__wrapper {
    position: relative;
    z-index: 999;
    padding-top: 90px;
    margin: 0px auto;
    height: 100%;

    &__sections {
      display: flex;
      justify-content: center;
      gap: 20px;
      padding-bottom: 40px;

      &__label {
        text-transform: uppercase;
        position: relative;
        font-size: 13px;

        &.active {
          transition: all 0.6s ease-in-out;
          font-weight: 500;
          border-bottom: 1px solid #0000005b;
        }
      }
    }

    &__icon {
      position: absolute;
      top: 60px;
      right: 15px;
      cursor: pointer;
    }

    &__categories {
      display: flex;
      flex-direction: column;
      gap: 20px;

      overflow-y: scroll;
      height: 80vh;
      padding-bottom: 90px;

      // padding-bottom: 60px;

      &__item {
        text-align: center;
        font-size: 12px;
        text-transform: uppercase;

        &.animate {
          animation: scaleAnimation 0.3s ease-in-out;
        }
      }
    }

    &__bottom {
      border-top: 1px solid #9999995b;
      width: 100%;
      padding: 20px 15px;
      position: absolute;
      bottom: 0;
      background-color: white;
      display: flex;
      z-index: 99999;
      align-items: center;
      justify-content: space-between;

      &__item {
        user-select: none;

        &::selection {
          display: none;
        }

        font-size: 10px;
        text-transform: uppercase;
      }

      &__dropdown {
        border: none;
        background-color: #fff;
        outline: none;
        font-size: 11px;
        text-transform: uppercase;
      }
    }
  }
}

@keyframes scaleAnimation {
  0% {
    opacity: 0.1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}