$iconsColor: #000;

@mixin df {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header {
  position: relative;
  z-index: 999999;
  width: 100%;
  margin-bottom: -62px;

  &__wrapper {
    padding: 0px 15px;
    @include df;

    &__left {
      @include df;

      &__icon {
        margin-right: 10px;
        color: $iconsColor;
      }

      &__logo {
        width: 120px;
        transform: translateY(21px);
        transition: opacity 0.4s ease-in-out;
        fill: #000 !important;
        color: #000 !important;

        &--hide {
          opacity: 0;
          z-index: -1;
        }
      }
    }

    &__right {
      @include df;

      gap: 4px;

      &__country {
        position: relative;


        &__selected {
          cursor: pointer;
        }

        &__list {
          position: absolute;
          z-index: 999;
          padding: 10px;
          border-radius: 10px;
          background-color: white;
          right: 0px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
          /* Adjust values as needed */

          &__item {
            display: flex;
            gap: 5px;
            width: 100%;
            margin-bottom: 4px;
            font-size: 12px;
            cursor: pointer;

          }

        }
      }

      &__icon {
        color: $iconsColor;
      }
    }
  }

  &--v2 {

    .header__wrapper__left__icon,
    .header__wrapper__right__icon {
      color: #000;
    }

    background-color: rgb(255 255 255 / 95%);
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    margin-bottom: 0px;

    .header__wrapper {
      &__filter {
        position: absolute;
        right: 50%;
        top: 50%;
        font-size: 11px;
        text-transform: uppercase;
        font-weight: 400;
        color: #000000a1;
        transform: translate(50%, -50%);

        &--hide {
          display: none;
        }
      }
    }
  }

  &--icon {
    color: white !important; // for home page change me
  }
}