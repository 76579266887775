.card {
  &__wrapper {
    &__media {
      position: relative;
      width: 100%;
      height: 400px;

      @media screen and (max-width: 768px) {
        height: 260px;
      }

      @media screen and (max-width: 380px) {
        height: 230px;
      }

      &__image {
        position: absolute;
        object-fit: cover;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }

    &__lower {
      padding-top: 5px;
      padding-left: 6px;
      display: flex;
      flex-direction: column;
      gap: 5px;

      &__price {
        font-size: 10px;
        color: #00000088;
        text-transform: uppercase;

        &.sale {
          text-decoration: line-through;
        }
      }

      &__name {
        font-size: 10px;
        color: #00000088;
        text-transform: uppercase;
      }

      &__sale {
        background-color: #ffe800;
        color: #0000008a;
        padding: 0 5px;
        display: inline-block;
        font-size: 10px;
        width: fit-content;
        text-transform: uppercase;
      }
    }
  }
}