.search {
  height: 100vh;
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 99999;
  background-color: white;
  width: 100%;
  @media screen and (min-width: 768px) {
    width: 40%;
  }
  transition: left 0.3s ease-in-out;
  &--show {
    left: 0;
  }
  &__wrapper {
    position: relative;
    padding-top: 80px;
    max-width: 95%;
    margin: 0px auto;
    &__icon {
      position: absolute;
      top: 60px;
      right: 15px;
      cursor: pointer;
    }
    &__input {
      font-size: 13px;
      &::placeholder {
        font-size: 13px;
      }
    }
  }
}
