.searchItem {
  &__wrapper {
    padding: 10px;
    margin: 8px 0px;
    display: flex;
    gap: 15px;
    user-select: none;

    cursor: pointer;
    border-radius: 20px;
    &:hover {
      background-color: #eee;
    }
    &__left {
      &__img {
        width: 60px;
        height: 90px;
        object-fit: cover;
        border-radius: 10px;
      }
    }
    &__right {
      &__name {
        padding-top: 5px;
        font-weight: 400;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 0.8px;
      }
      &__price {
        padding-top: 10px;
        font-weight: 400;
        font-size: 11px;
      }
    }
  }
}
