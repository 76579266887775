.collection {
  &__wrapper {
    // margin-top: 15px;
    padding: 0px 5px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &__item {
      margin-bottom: 20px;
      // padding-left: 5px;
      width: 24%;

      @media screen and (max-width: 768px) {
        width: 49%;
      }
    }

    &__viewMore {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      flex-direction: column;
      align-items: center;
      gap: 26px;

      &__btn {
        // border-bottom: 5px solid black;
        font-size: 10px;
        padding-bottom: 5px;

        max-width: 90px !important;
        min-width: 90px !important;
        width: 90px !important;
        height: 18px !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

// width: 100%;
// display: flex;
// flex-wrap: wrap;
// &__item {
//   padding-left: 5px;
//   width: 24%;
//   @media screen and (max-width: 768px) {
//     width: 48%;
//   }
// }
// }