@mixin dfc {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.quantityCounter {
  &__wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
    border: 1px solid rgb(204 204 204 / 100%);
    justify-content: center;
    padding: 5px 10px;
    width: 90px;
    height: 30px;
    &__count {
      font-size: 11px;
      @include dfc;
    }
    &__left {
      @include dfc;
      height: 100%;
      width: 100%;
      &__icon {
        font-size: 9px;
      }
    }
    &__right {
      @include dfc;
      height: 100%;
      width: 100%;
      &__icon {
        font-size: 9px;
      }
    }
  }
}
