.footer {
  &__wrapper {
    height: 100vh;
    background-color: white;
    padding: 100px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    &__title {
      font-size: 14px !important;
      text-transform: uppercase;
    }
    &__socials {
      max-width: 220px;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      &__item {
        font-size: 11px;
        flex: 1;
        cursor: pointer;
      }
    }
    &__bottom {
      display: flex;
      align-items: center;
      // gap: 10px;

      &__link {
        text-decoration: underline;
      }
      &__item {
        font-size: 11px;
        &.separator {
          &::after {
            padding: 4px;
            content: "|";
          }
        }
      }
    }
  }
}
